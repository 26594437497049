import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Observable, of, ReplaySubject, concat } from "rxjs";
import { filter, switchMap, map, mergeMap, take } from "rxjs/operators";
import { Navigation } from "./discoverer/app-core"; // Assuming you have the correct interface
import { Location } from '@angular/common';
import { NavigationHelper } from "./vchems-bol/services/navigation-helper";

@Injectable({
    providedIn: 'root',
})
export class NavigationItemsService {

    constructor(private httpClient: HttpClient, private location: Location, private navigationHelper: NavigationHelper) {
    }

    getNavigationItems(): Observable<Navigation> {
        if (this.navigationHelper.currentNavigation)
            return of(this.navigationHelper.currentNavigation);
        return of({
            items: [
                {
                    id: 'default-item',
                    title: 'Loading...',
                    type: 'basic',
                    icon: 'hourglass_empty',
                    link: '/'
                }
            ]
        } as Navigation);
    }
}
