<div class="bg-gray-800 text-white">
    <nav class="container mx-auto flex justify-between items-center py-4">
        <!-- Logo / Branding -->
        <div class="text-2xl font-bold">App Navigation</div>

        <ul class="flex space-x-6">
            <li>
                <a routerLink="/lab" class="block bg-gray-700 px-4 py-2 rounded-md border border-gray-600 hover:bg-blue-500 hover:border-blue-600 hover:text-white transition duration-300">
                    Lab
                </a>
            </li>
            <li>
                <a routerLink="/vchems-bol" class="block bg-gray-700 px-4 py-2 rounded-md border border-gray-600 hover:bg-blue-500 hover:border-blue-600 hover:text-white transition duration-300">
                    BOL
                </a>
            </li>
        </ul>

        <div class="flex items-center space-x-4">
            <!-- <button class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                Logout
            </button> -->
        </div>
    </nav>
</div>
