import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

// Extending discoverer IconModule
@NgModule()
export class AppIconsModule
{
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    )
    {
        // Register icon sets
        this._matIconRegistry.addSvgIconSetInNamespace('custom_icons', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom-icons.svg'));
    }
}
