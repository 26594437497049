import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from './discoverer/app-core';
import { Location } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class AppKeyRedirectGuard implements CanActivate {
    constructor(private router: Router, private userDataService: UserDataService, private location: Location) { }
    userData;
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.userData = await this.userDataService.getUser();

        const currentUrl = this.location.path();

        const hasLabRole = this.userData.roles.has('lab:admin');
        const hasVchemsRole = this.userData.roles.has('vchems-bol:admin');
        const appKey = localStorage.getItem('key');

        if (appKey) {
            if (appKey.toLocaleLowerCase() == 'lab' && hasLabRole) {
                this.router.navigate(['/lab']);
            } else if (appKey.toLocaleLowerCase() == 'vchems-bol' && hasVchemsRole) {
                this.router.navigate(['/vchems-bol']);
            } else {
                this.router.navigate(['/vchems-bol']);
            }
            return false;

        }

    }
}
