import { BrowserModule } from '@angular/platform-browser';
import {  NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseConfigModule } from '@fuse/services/config/config.module';
import { LayoutModule } from '@angular/cdk/layout';
import { AbstractNavigationItemsService, appConfig, AppCoreModule } from './discoverer/app-core';
import { FuseModule } from './discoverer/fuse';
import { NavigationItemsService } from './navigation-items';
import { AppIconsModule } from './app-icons.module';
import { NavigationComponent } from './navigation/navigation.component';


@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,

        // Extending discoverer IconModule
        AppIconsModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseConfigModule.forRoot(appConfig),
        FuseModule,

        // Core module of your application
        AppCoreModule.forRoot({
            provide: AbstractNavigationItemsService,
            useClass: NavigationItemsService
          }),

        // Layout module of your application
        LayoutModule    ],
    bootstrap: [AppComponent],
    exports: [
    ]
})
export class AppModule {
}
