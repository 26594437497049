export const environment = {
  isDevelopment: false,
  production: true,
  APIEndpoint: 'https://vchems.ignite.dev.jo.capella.io/',
  frontendEndpoint: 'https://vchems-bol.dev.jo.capella.io/',
  VCH: {
    GraphQlURL: 'https://vchems-graphql.ignite.dev.jo.capella.io/v1/graphql'
  },
  VERSION: require('../../package.json').version,
  auth: {
    API: 'https://vchems-auth.dev.jo.capella.io/',
    logoutURL: '',
    loginURL: 'https://vchems-auth.dev.jo.capella.io/',
    productKey: 'VCHEMS-BOL',
    driversAccount: 1028,
    vchemsAccount: 1000

  }
};
