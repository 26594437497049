import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AppGuardService } from './app.guard.service';
import { InitialDataResolver } from './app.resolvers';
import { LandingComponent } from './discoverer/app-core/gateway-auth/landing/landing.component';
import { LayoutComponent } from './discoverer/app-core/layout/layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AppKeyRedirectGuard } from './role-redirect.gaurd';

const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    { path: '', pathMatch: 'full', component: NavigationComponent ,canActivate: [AppKeyRedirectGuard] },
    {
        path: '',
        component: LayoutComponent,
        data: {

            layout: 'modern'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'vchems-bol', loadChildren: () => import('./vchems-bol/vchems-bol.module').then(m => m.VCHEMSBOLModule), canActivate: [AppGuardService] },
            { path: 'vchems-bol', outlet: 'dialog', loadChildren: () => import('./vchems-bol/vchems-bol.module').then(m => m.VCHEMSBOLModule), canActivate: [AppGuardService] },
            {
                path: 'lab',
                loadChildren: () => import('./lab/lab.module').then(m => m.LabModule),
                canActivate: [AppGuardService]
            },
            { path: 'lab', outlet: 'dialog', loadChildren: () => import('./lab/lab.module').then(m => m.LabModule), canActivate: [AppGuardService] },
            {
                path: 'chofcd',
                loadChildren: () => import('./chain-of-custudy/ch.module').then(m => m.ChModule),
                canActivate: [AppGuardService]
            },
            { path: 'chofcd', outlet: 'dialog', loadChildren: () => import('./chain-of-custudy/ch.module').then(m => m.ChModule), canActivate: [AppGuardService] },


        ]

    }


];

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService]
})
export class AppRoutingModule {
}
