import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserData, UserDataService } from './discoverer/app-core/user-data.service';
import { AuthService, NavigationService } from './discoverer/app-core';
import { NavigationHelper } from './vchems-bol/services/navigation-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
    public loggedIn = false;
    title = 'Valence Chems';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
        private _userDataService: UserDataService,
        private _authService: AuthService,
        private httpClient: HttpClient,
        private _navigationHelper: NavigationHelper,
        private navigationService: NavigationService
    ) {
        this._navigationHelper.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(navigation => {
                if (navigation) {
                    this.navigationService.set(navigation);
                }
            });
        this._authService.gatewayIdentityUserObservable.subscribe(async user => {
            if (!!user) {
                if (user) {
                    console.log('User data received:', user); // Add this log
                }
                const userData = new UserData();
                userData.name = user.fullName;
                userData.email = user.email;
                userData.id = user.userId;
                userData.token = user.token;
                userData.accountId = user.accountId;
                userData.roles = new Set<string>(user.roles);
                const projects = (await this.httpClient.get<any[]>(`api/v1/user/projects/${user.userId}`).toPromise())
                userData.permissions = projects;
                this._userDataService.setUser(userData);
            }
        });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
