import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Navigation } from '@app/discoverer/app-core';

@Injectable({
    providedIn: 'root'
})
export class NavigationHelper {
    private _navigationSubject: BehaviorSubject<Navigation | null> = new BehaviorSubject<Navigation | null>(null);
    public navigation$: Observable<Navigation | null> = this._navigationSubject.asObservable();

    public currentNavigation: Navigation | null = null;

    constructor(private httpClient: HttpClient, private router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const currentUrl = event.urlAfterRedirects;
                this.loadNavigation(currentUrl);
            });
    }

    loadNavigation(currentUrl: string): void {
        const apiUrl = currentUrl.includes('/lab') ? 'api/navigation/lab' : currentUrl.includes('/chofcd') ? 'api/navigation/chofcd': 'api/navigation';

        this.httpClient.get<Navigation>(apiUrl).subscribe(
            (navigation) => {
                this._navigationSubject.next(navigation);
                this.currentNavigation = navigation;
            },
            (error) => {
                console.error('Error fetching navigation:', error);
            }
        );
    }

    clearNavigation(): void {
        this._navigationSubject.next(null);
        this.currentNavigation = null;
    }
}
